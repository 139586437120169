
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';
import { DataItem } from '@/types/app.global';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'Product',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'backerNo',
              label: '品牌号',
              type: 'select',
              options: mapper?.device.backerNo
            },
          ]
        },
        remote: {
          action: https?.mall.pageProduct
        }
      },
      cols: [
        {
          field: 'name',
          label: '商品名'
        },
        {
          field: 'col-basic',
          label: '基础信息',
          group: [
            {
              field: 'backerNo',
              label: '品牌号',
              options: mapper?.device.backerNo
            },
            {
              field: 'freezeFlag',
              label: '冻结类型',
              options: mapper?.device.freezeFlag
            },
            {
              field: 'type',
              label: '商品类型',
              options: mapper?.mall.productType
            },
          ]
        },
        {
          field: 'col-price',
          label: '价格',
          width: 140,
          group: [
            {
              field: 'price',
              label: '价格'
            },
            {
              field: 'quantity',
              label: '数量'
            },
            {
              field: 'discount',
              label: '折扣'
            },
          ]
        },
        {
          field: 'heat',
          label: '热度',
          width: 80
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.mall.productStatus,
          width: 80
        },
        {
          field: 'term',
          label: '期限(天)',
          width: 80
        },
        {
          field: 'outline',
          label: '简介'
        },
        {
          field: 'detailIntroduction',
          label: '详细介绍'
        },
        {
          field: 'col-policy',
          label: '政策信息',
          width: 240,
          group: [
            {
              field: 'activateDesc',
              label: '激活',
              format: (val: any, data: DataItem) => val + '/' + data.term
            },
            {
              field: 'rewardDesc',
              label: '返现'
            },
          ]
        },
        {
          field: 'picturePath',
          label: '图片',
          ability: 'preview',
          width: 120
        },
        {
          field: 'col-time',
          label: '时间',
          width: 220,
          group: [
            {
              field: 'createTime',
              label: '创建'
            },
            {
              field: 'updateTime',
              label: '更新'
            }
          ]
        }
      ],
      schema: {
        width: '50%',
        content: {
          divide: 'policyTag',
          cols: [
            {
              field: 'name',
              label: '商品名称',
              disabled: ['edit'],
              required: true
            },
            {
              field: 'backerNo',
              label: '品牌号',
              type: 'select',
              options: mapper?.device.backerNo,
              disabled: ['edit'],
              required: true
            },
            {
              field: 'freezeFlag',
              label: '冻结类型',
              type: 'radio',
              options: mapper?.device.freezeFlag,
              disabled: ['edit'],
              required: true
            },
            {
              field: 'type',
              label: '商品类型',
              type: 'select',
              options: mapper?.mall.productType,
              disabled: ['edit'],
              required: true
            },
            {
              field: 'price',
              label: '价格',
              type: 'number',
              scale: 2,
              disabled: ['edit'],
              required: true
            },
            {
              field: 'discount',
              label: '折扣',
              type: 'number',
              scale: 2,
              disabled: ['edit'],
              required: true
            },
            {
              field: 'quantity',
              label: '数量',
              type: 'number',
              disabled: ['edit'],
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'select',
              options: mapper?.mall.productStatus,
              required: true
            },
            {
              field: 'heat',
              label: '商城热度',
              type: 'number',
              defVal: 0,
              required: true
            },
            {
              field: 'term',
              label: '期限(天)',
              type: 'number',
              required: true
            },
            {
              field: 'policyTag',
              label: '商城政策',
              type: 'select',
              mapper: ['codeId', 'codeName'],
              remote: {
                params: { type: constants.enums.enumType.mallPolicy },
                action: https?.mall.listEnum
              },
              evaluate: (data: DataItem) => ({ policy: data.codeId, policyTag: data.codeName }),
              disabled: ['edit'],
              required: true
            },
            {
              field: 'activateDesc',
              label: '激活政策',
              type: 'select',
              mapper: ['activateCode', 'activateDesc'],
              remote: {
                action: https?.market.listActivatePolicy
              },
              evaluate: (data: DataItem) => ({ activateId: data.id, activateDesc: data.activateDesc }),
              disabled: ['edit'],
              required: true
            },
            {
              field: 'rewardDesc',
              label: '返现政策',
              type: 'select',
              mapper: ['rewardCode', 'rewardDesc'],
              remote: {
                action: https?.market.listRewardPolicy
              },
              evaluate: (data: DataItem) => ({ rewardPolicyId: data.id, rewardDesc: data.rewardDesc }),
              disabled: ['edit'],
              required: true
            },
            {
              field: 'activityType',
              label: '押金编号',
              type: 'select',
              mapper: ['codeId', 'codeName'],
              remote: {
                params: { type: constants.enums.enumType.activityType },
                action: https?.mall.listEnum
              },
              required: true
            },
            {
              field: 'activityId',
              label: '组合活动ID',
              required: true
            },
            {
              field: 'outline',
              label: '简介',
              required: true
            },
            {
              field: 'detailIntroduction',
              label: '详细介绍',
              type: 'textarea',
              required: true
            },
            {
              field: 'picturePath',
              label: '商品图片',
              type: 'upload-pic-card',
              required: true
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.mall.createProduct },
        update: { action: https?.mall.updateProduct }
      }
    };

    return { tableOpts };
  }
});
